<div class="sticky-nav__placeholder" #navPositionRef></div>

<div class="sticky-nav__outer" [class.sticky-nav__outer--short]="isNavShort" [class.sticky-nav__outer--item-page]="type === 'item'"  [style.top]="navAbsoluteTop + 'px'" [style.position]="navCssPosition">
  <div class="sticky-nav__inner" (mousedown)="startScrollingNav($event)" (touchstart)="startScrollingNav($event)" #navInnerRef>
    <div class="sticky-nav__swiper" #navSwiperRef>
      <div *ngIf="isLoading && (!items || !items.length)">
        <div class="sticky-nav__link">
          <div class="sticky-nav__link-value--full">
            <app-text-holder [desktopTexts]="[80, 140, 100]"></app-text-holder>
          </div>
          <div class="sticky-nav__link-value--short">
            <app-text-holder [desktopTexts]="[80, 140, 100]"></app-text-holder>
          </div>
        </div>
      </div>

      <div *ngFor="let item of items" class="sticky-nav__item">
        <div (click)="handleItemNavClick($event, item)" (touchend)="handleItemNavClick($event, item)" class="sticky-nav__link" [class.sticky-nav__link--active]="item === activeItem" [id]="'nav-item-' + item.idAttr">
          <div class="sticky-nav__link-value--full">{{item.fullName}}</div>
          <div class="sticky-nav__link-value--short">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</div>