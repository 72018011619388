import { Component } from '@angular/core';
import { projectData } from './project-data';
import { InitService } from '@core/services/init/init.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    public initService: InitService
  ) {
    this.initService.init({ projectData });
  }
}
