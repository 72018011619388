<div class="header-placeholder" [class.header-placeholder--banner]="appHomepageBannerText && isNativeAppVisible && !isActivePrompt" [class.header-placeholder--prompt]="!isNativeAppVisible && isActivePrompt" [class.header-placeholder--prompt-banner]="appHomepageBannerText && isNativeAppVisible && isActivePrompt"></div>

<ng-container *ngFor="let message of messagePopupService.messages">
  <div class="alert alert_{{message.type}} my-2">
    {{message.text}}
  </div>
</ng-container>

<div class="header-container"  [class.header-container--banner]="appHomepageBannerText && isNativeAppVisible && !isActivePrompt" [class.header-placeholder--prompt]="!isNativeAppVisible && isActivePrompt" [class.header-container--prompt-banner]="appHomepageBannerText && isNativeAppVisible && isActivePrompt">

  <app-install-prompt *ngIf="showInstallPrompt" (statusPrompt)="handlePrompt($event)"></app-install-prompt>
  <app-header-banner *ngIf="appHomepageBannerText && isNativeAppVisible" [text]="appHomepageBannerText"></app-header-banner>

  <header class="header" [class.header--short]="isShort">
    <a routerLink="/" class="logo header__logo" title="Go to homepage">
      <img src="/assets/svg/logo.svg" alt="7days Performance" class="logo__img">
    </a>

    <nav class="header__nav">
      <ul class="main-nav">
        <li class="main-nav__item">
          <a routerLink="/current-competitions" class="main-nav__link" title="">Cars and Bikes</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/current-tech-competitions" class="main-nav__link" title="">Tech and Luxury</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/cash-competitions" class="main-nav__link" title="">Tax Free Cash</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/competition-winners/featured" class="main-nav__link" title="">Winners</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/draw-results" class="main-nav__link" title="">Draw Results</a>
        </li>
        <!-- <li class="main-nav__item">
          <a routerLink="/about-us" class="main-nav__link" title="">About Us</a>
        </li> -->
        <li class="main-nav__item">
          <a routerLink="/faq" class="main-nav__link" title="">FAQ</a>
        </li>
      </ul>

      <button class="header__button header__button_sm nav-button" (click)="handleMobileMenuOpen()">
        <svg-icon name="hamburger-new" class="nav-button__icon nav-button__icon_hamburger"></svg-icon>
      </button>
      <a routerLink="/cart" class="header__button nav-button">
        <span class="nav-button__label" *ngIf="(cart$ | async) as cart; else noCart">{{ cart.cached_item_count }}</span>
        <ng-template #noCart>
        <span class="nav-button__label">
          <span class="nav-button__loading">
            <svg-icon name="circle-notch" [spin]="true"></svg-icon>
          </span>
        </span>
        </ng-template>
        <!-- <svg-icon name="shopping-cart-7-d" class="nav-button__icon nav-button__icon_shopping-cart"></svg-icon> -->
        <svg-icon name="cart-new" class="nav-button__icon nav-button__icon_shopping-cart"></svg-icon>
      </a>
      <a routerLink="/my-account" class="header__button nav-button">
        <svg-icon name="profile-new" class="nav-button__icon nav-button__icon_account"></svg-icon>
      </a>
    </nav>
  </header>

  <!-- Mobile menu -->
  <div class="mobile-menu" [ngClass]="{'mobile-menu_shown': isMobileMenuVisible}" (click)="handleMobileMenuClose()">
    <button class="mobile-menu__close">
      <svg-icon name="close-7-d" class="mobile-menu__close-icon"></svg-icon>
    </button>
    <aside class="mobile-menu__panel" (click)="stopPropagation($event)">
      <nav class="mobile-menu__nav">
        <ul class="mobile-menu__nav-list">

          <li class="mobile-menu__item">
            <span  class="mobile-menu__title"> Competitions</span>
          </li>
          <li class="mobile-menu__item">
            <a routerLink="/current-competitions" (click)="handleMobileMenuClose()" class="mobile-menu__link" title="">Cars & Bikes</a>
          </li>
          <li class="mobile-menu__item">
            <a routerLink="/current-tech-competitions" (click)="handleMobileMenuClose()" class="mobile-menu__link" title="">Tech & Luxury</a>
          </li>
          <li class="mobile-menu__item">
            <a routerLink="/cash-competitions" (click)="handleMobileMenuClose()" class="mobile-menu__link" title="">Cash</a>
          </li>
          <li class="mobile-menu__item">
            <a routerLink="/instant-wins" (click)="handleMobileMenuClose()" class="mobile-menu__link" title=""> Instant Wins</a>
          </li>
          <li class="mobile-menu__item">
            <span class="mobile-menu__divider"></span>
          </li>
          <li class="mobile-menu__item">
            <a routerLink="/competition-winners/featured" (click)="handleMobileMenuClose()" class="mobile-menu__link" title="">Winners</a>
          </li>
          <li class="mobile-menu__item">
            <a routerLink="/draw-results" (click)="handleMobileMenuClose()" class="mobile-menu__link" title="">Draw
              Results</a>
          </li>
          <!-- <li class="mobile-menu__item">
            <a routerLink="/about-us" (click)="handleMobileMenuClose()" class="mobile-menu__link" title="">About Us</a>
          </li> -->
          <li class="mobile-menu__item">
            <a routerLink="/faq" (click)="handleMobileMenuClose()" class="mobile-menu__link" title="">FAQ</a>
          </li>
        </ul>
      </nav>
      <div class="mobile-menu__bottom-wrapper">
        <p class="mobile-menu__text">7days Social</p>
        <div class="mobile-menu__socials">
          <a href="https://www.facebook.com/7daysPerformance/"
             target="_blank"
             rel="nofollow"
             class="mobile-menu__social-link"
             title=""
             (click)="handleMobileMenuClose()">
            <svg-icon name="facebook-7-d" class="mobile-menu__social-icon"></svg-icon>
          </a>
          <a href="https://www.instagram.com/7daysperformance/"
             target="_blank"
             rel="nofollow"
             class="mobile-menu__social-link"
             title=""
             (click)="handleMobileMenuClose()">
            <svg-icon name="instagram-full" class="mobile-menu__social-icon"></svg-icon>
          </a>
        </div>
      </div>
      <div class="mobile-menu__bottom-wrapper mobile-menu__bottom-wrapper--app" *ngIf="!isNativeAppVisible && (isIosApp || isAndroidApp)">
        <p class="mobile-menu__text">Download App</p>
        <div class="mobile-menu__download-app">
          <a href="https://apps.apple.com/gb/app/7days-performance/id1455876956" target="_blank"  *ngIf="isIosApp" >
            <svg-icon class="mobile-menu__app-icon" name="app-store"></svg-icon>
          </a>
          <a href="https://play.google.com/store/apps/details?id=co.uk.daysperformance7.app" target="_blank" *ngIf="isAndroidApp">
            <svg-icon class="mobile-menu__app-icon" name="google-store"></svg-icon>
          </a>
        </div>
      </div>
    </aside>
  </div>

  <div class="native-nav" *ngIf="isNativeAppVisible">
    <a class="native-nav__button" (click)="goBack($event)">
      <div class="native-nav__icon">◄</div>
      <div class="native-nav__label">Back</div>
    </a>
    <a routerLink="/my-account" class="native-nav__button">
      <div class="native-nav__icon"><img src="/assets/images/native/icon-account.svg"
                                         alt="My Account"
                                         width="15"
                                         height="15"/></div>
      <div class="native-nav__label">My Account</div>
    </a>
    <a routerLink="/cart"
       class="native-nav__button"
       [class.native-nav__button_inactive]="(cart$ | async) === null || (cart$ | async).cached_item_count === 0">
      <div class="native-nav__icon"><img src="/assets/images/native/icon-shopping-cart.png"
                                         alt="Basket"
                                         width="15"
                                         height="15"/></div>
      <div class="native-nav__label">Basket</div>
    </a>
  </div>

</div>
